// 请求模块

import axios from 'axios'
import store from '@/store/index'
import router from '@/router'
// import { getItem } from '@/utils/storage'
const request = axios.create({
  baseURL: 'https://expresscredit.top/api'
})
export default request
// 请求拦截器
const LANGTH = '?lang=phi'
// const LANGZH = '?lang=zh_CN'
request.interceptors.request.use(function (config) {
  // Do something before request is sent
  const { token } = store.state
  if (token) {
    config.headers.Authorization = token
  }
  config.url = config.url + LANGTH
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

// 响应拦截器
request.interceptors.response.use(function (response) {
  if (response.data.code === 501) {
    if (router.history.current.path === '/' || router.history.current.path === '/service') return false
    router.push({
      path: '/login'
    })
  }
  return response
}, function (error) {
  return Promise.reject(error)
})
